import { useState } from "react";
import Icon from "@mdi/react";
import {
  mdiMicrophone,
  mdiMicrophoneOff,
  mdiVideo,
  mdiVideoOff,
  mdiPhoneHangup,
} from "@mdi/js";
import { createClient } from "agora-rtc-react";
import { useSelector } from "react-redux";

function Controls(props) {
  const appId = process.env.REACT_APP_AGORA_APP_ID;
  const token = useSelector((state) => state.channelToken.token);

  const config = { codec: "h264", mode: "rtc", appId: appId, token: token };
  const useClient = createClient(config);

  const client = useClient();

  const { tracks, setStart, setInCall } = props;
  const [trackState, setTrackState] = useState({
    video: true,
    audio: true,
  });

  const toggleAudio = async () => {
    await tracks[0].setEnabled(!trackState.audio);
    setTrackState((prevState) => {
      return { ...prevState, audio: !trackState.audio };
    });
  };

  const toggleVideo = async () => {
    await tracks[1].setEnabled(!trackState.video);
    setTrackState((prevState) => {
      return { ...prevState, video: !trackState.video };
    });
  };

  const endCall = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
  };

  return (
    <div className="flex justify-center items-center h-full">
      <div
        onClick={toggleAudio}
        className={`${
          !trackState.audio
            ? "bg-red-600 w-14 h-14 flex justify-center items-center rounded-full p-3 mx-2 cursor-pointer"
            : "bg-slate-400 w-14 h-14 flex justify-center items-center rounded-full p-3 mx-2 cursor-pointer"
        }`}
      >
        <Icon
          path={!trackState.audio ? mdiMicrophoneOff : mdiMicrophone}
          size={1}
          color={`${!trackState.audio ? "white" : "black"}`}
        />
      </div>

      <div
        onClick={toggleVideo}
        className={`${
          !trackState.video
            ? "bg-red-600 w-14 h-14 flex justify-center items-center rounded-full p-3 mx-2 cursor-pointer"
            : "bg-slate-400 w-14 h-14 flex justify-center items-center rounded-full p-3 mx-2 cursor-pointer"
        }`}
      >
        <Icon
          path={!trackState.video ? mdiVideoOff : mdiVideo}
          size={1}
          color={`${!trackState.video ? "white" : "black"}`}
        />
      </div>

      <div
        onClick={endCall}
        className="w-14 h-14 flex justify-center items-center rounded-full p-3 bg-red-600 mx-2 cursor-pointer"
      >
        <Icon path={mdiPhoneHangup} size={1} color="white" />
      </div>
    </div>
  );
}

export default Controls;
