import { useEffect, useState } from "react";
import VideoCall from "./VideoCall";
import { useParams } from "react-router-dom";
import { useFetchChannelTokenQuery } from "../redux/channel";
import { useDispatch, useSelector } from "react-redux";
import { saveToken } from "../redux/channelSlice";

function Main() {
  let dispatch = useDispatch();
  let channelToken = useSelector(state => state.channelToken.token)
  const [inCall, setInCall] = useState(false);

  let params = useParams();
  let channelName = params.channelName;

  const { data, error, isLoading } = useFetchChannelTokenQuery(channelName);

  useEffect(() => {
    if(data?.token){
      dispatch(saveToken(data.token))
    }
  }, [data])

  const joinCall = () => {
    setInCall(true);
  };

  if (error) {
    return (
      <div className="centered">
        <div className="bg-white w-fit p-10 flex flex-col justify-center items-center">
          <div className="mb-5 text-center text-lg">
            Oops!! Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      {isLoading ? (
        <div className="centered">
          <div className="bg-white w-fit p-10 flex flex-col justify-center items-center">
            <div className="mb-5 text-center text-lg">
              Please click on the button below to start your call.
            </div>
            <div
              style={{ backgroundColor: "#001d3d" }}
              className="px-3 py-1 text-lg text-white w-fit cursor-pointer"
            >
              <div className="flex justify-center">
                <svg
                  className="animate-spin mr-3 h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Loading...
              </div>
            </div>
          </div>
        </div>
      ) : inCall && channelToken ? (
        <VideoCall setInCall={setInCall} />
      ) : (
        <div className="centered">
          <div className="bg-white w-fit p-10 flex flex-col justify-center items-center">
            <div className="mb-5 text-center text-lg">
              Please click on the button below to start your call.
            </div>
            <div
              onClick={joinCall}
              style={{ backgroundColor: "#001d3d" }}
              className="px-3 py-1 text-lg text-white w-fit cursor-pointer"
            >
              Join call
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Main;
