import { AgoraVideoPlayer } from "agora-rtc-react";
// import { useState, useEffect } from "react";

function Video(props) {
  const { users, tracks } = props;
  // const [gridSpacing, setGridSpacing] = useState(12);

  // useEffect(() => {
  //   setGridSpacing(Math.max(Math.floor(12 / (users.length + 1)), 4));
  // }, [users]);

  if (users.length === 1) {
    return (
      <div className="h-full relative">
        {users.length > 0 &&
          users.map((user) => {
            if (user.videoTrack) {
              return (
                <AgoraVideoPlayer
                  videoTrack={user.videoTrack}
                  key={user.uid}
                  className="user-video-styling"
                />
              );
            } else return null;
          })}
        <AgoraVideoPlayer
          videoTrack={tracks[1]}
          className="initiator-video-styling"
        />
      </div>
    );
  } else {
    return (
      <div className={`h-full videos`}>
        <AgoraVideoPlayer
          videoTrack={tracks[1]}
          style={{ height: "100%", width: "100%", padding: "2rem" }}
        />
        {users.length > 0 &&
          users.map((user) => {
            if (user.videoTrack) {
              return (
                <AgoraVideoPlayer
                  videoTrack={user.videoTrack}
                  key={user.uid}
                  style={{ height: "100%", width: "100%", padding: "2rem" }}
                />
              );
            } else return null;
          })}
      </div>
    );
  }
}

export default Video;
