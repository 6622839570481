import Main from './components/Main';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { paths } from './urlPaths';

function App() {
  return (
    <div className="video-container">
      <BrowserRouter>
        <Routes>
          <Route path={paths.meeting} element={<Main/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
