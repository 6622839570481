import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: '',
  }

export const channelSlice = createSlice({
    name: 'channelToken',
    initialState,
    reducers: {
        saveToken: (state, action) => {
            state.token = action.payload
        }
    }
})

export const { saveToken } = channelSlice.actions
export default channelSlice.reducer