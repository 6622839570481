import { useEffect, useState } from "react";
import { useMicrophoneAndCameraTracks } from "../hooks/agoraHooks";
import Video from "./Video";
import Controls from "./Controls";
import { useParams } from "react-router-dom";
import { createClient } from "agora-rtc-react";
import { useSelector } from "react-redux";

function VideoCall(props) {
  const { setInCall } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);

  const appId = process.env.REACT_APP_AGORA_APP_ID;
  const token = useSelector((state) => state.channelToken.token);

  const config = { codec: "h264", mode: "rtc", appId: appId, token: token }
  const useClient = createClient(config)
  const client = useClient();

  const { ready, tracks } = useMicrophoneAndCameraTracks();
  let params = useParams();

  let channelName = params.channelName

  useEffect(() => {
    let init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);

        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }

        if (mediaType === "audio") {
          user.audioTrack.play();
        }
      });

      client.on("user-unpublished", (user, mediaType) => {
        if (mediaType === "audio") {
          if (user.audioTrack) {
            user.audioTrack.stop();
          }
        }

        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((prevUser) => prevUser.uid !== user.uid);
          });
        }
      });

      client.on("user-left", (user) => {
        setUsers((prevUsers) => {
          return prevUsers.filter((prevUser) => prevUser.uid !== user.uid);
        });
      });

      try {
        await client.join(config.appId, name, config.token, null);
      } catch (error) {
        console.log(error);
      }

      if (tracks) {
        await client.publish([tracks[0], tracks[1]]);
      }

      setStart(true);
    };

    if (ready && tracks) {
      try {
        init(channelName);
      } catch (error) {
        console.log(error);
      }
    }
  }, [channelName, ready, tracks]);

  return (
    <div className="h-screen flex flex-col">
      <div style={{ height: "90vh" }}>
        {start && tracks && <Video tracks={tracks} users={users} />}
      </div>
      <div style={{ height: "10vh" }}>
        {ready && tracks && (
          <Controls tracks={tracks} setStart={setStart} setInCall={setInCall} />
        )}
      </div>
    </div>
  );
}

export default VideoCall;
