import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { channel } from '../utils/endPoints'

let BASE_URL = process.env.REACT_APP_BASE_URL;

export const channelApi = createApi({
    reducerPath: 'channel',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        fetchChannelToken: builder.query({
            query: (channelName) => channel(channelName)
        })
    })
})

export const { useFetchChannelTokenQuery } = channelApi;