import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { channelApi } from "./channel";
import tokenReducer from "./channelSlice";

export const store = configureStore({
  reducer: {
    channel: channelApi.reducer,
    channelToken: tokenReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(channelApi.middleware),
});

setupListeners(store.dispatch);
